<template>
  <div class="page-content">
    <div class="form-content">
      <div class="form-item">
        <span class="title">产品类型：</span>
        <el-select class="my-input w300" v-model="addParams.prodType" placeholder="请选择">
          <el-option
            v-for="item in proItems"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="form-item">
        <span class="title">所属领域：</span>
        <el-select class="my-input w300" v-model="addParams.prodFunction" placeholder="请选择">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="form-item">
        <span class="title">产品标题：</span>
        <el-input class="w300" v-model="addParams.prodName" placeholder="产品名称"></el-input>
      </div>
      <!--          <div class="form-item">
                <span class="title">产品导言：</span>
                <el-input type="textarea" class="w300" size="mini" v-model="value" placeholder="产品名称"></el-input>
      </div>-->
      <div class="form-item">
        <span class="title">产品图片：</span>
        <div class="right-content">
          <div class="upload-btn">
            <i v-if="!addParams.prodImg" class="el-icon-plus"></i>
            <img v-if="addParams.prodImg" :src="addParams.prodImg" alt>
            <input type="file" class="upload-file" @change="handleFileChange">
          </div>
        </div>
      </div>
      <div class="form-item">
        <span class="title">产品视频：</span>
        <div class="right-content">
          <div class="upload-btn" v-if="!addParams.prodVideo">
            <i class="el-icon-plus"></i>
            <input type="file" class="upload-file" @change="handleFileChangeVideo">
          </div>
          <div class="videos" v-if="addParams.prodVideo">
            <video :src="addParams.prodVideo" controls="controls" width="400px" height="260px"></video>
            <div class="clear-btn">
              <el-button type="danger" round @click="clearVideo()">清空视频</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-item">
        <span class="title">正文内容：</span>
        <div class="right-content">
          <el-input type="textarea" :rows="5" placeholder="正文内容" v-model="addParams.prodContent"></el-input>
        </div>
      </div>
      <div class="form-item">
        <span class="title">正文描述：</span>
        <div class="right-content">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="正文描述"
            v-model="addParams.prodDescription"
          ></el-input>
        </div>
      </div>
      <div class="form-item">
        <span class="title">正文标注：</span>
        <div class="right-content">
          <el-input type="textarea" :rows="5" placeholder="正文标注" v-model="addParams.remark"></el-input>
        </div>
      </div>
      <div class="form-item">
        <span class="title">解决方案：</span>
        <div class="right-content">
          <el-button type="primary" class="mb5" round @click="add()">
            <i class="el-icon-plus"></i>增加解决方案
          </el-button>
          <div
            class="fanan-item"
            v-for="(item, index) in addParams.prodSolutions"
            :key="index"
          >
            <div class="form-item">
              <span class="title">解决方案标题：</span>
              <el-input class="w300" v-model="item.title" placeholder="解决方案标题"></el-input>
            </div>
            <div class="form-item">
              <span class="title">解决方案图片：</span>
              <div class="right-content">
                <div class="upload-btn uploadFiles">
                  <span>上传图片</span>
                  <input
                          type="file"
                          class="upload-file"
                          @change="handleFileChangeList($event, index)"
                  >
                </div>
                <div v-if="item.imgUrl" class="moreImgContent">
                  <div class="moreImgItem" v-for="(jieJueItem, indexTwo) in showJieJueImg(item.imgUrl)" :key="indexTwo">
                    <img :src="jieJueItem" alt="">
                    <i @click="deleteImg(index, indexTwo)" class="el-icon-error remove-icon"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-item">
              <span class="title">解决方案视频：</span>
              <div class="right-content">
                <div class="upload-btn" v-if="!item.videoUrl">
                  <i class="el-icon-plus"></i>
                  <input
                    type="file"
                    class="upload-file"
                    @change="handleFileChangeVideoList($event, index)"
                  >
                </div>
                <div class="videos" v-if="item.videoUrl">
                  <video
                    :src="addParams.prodVideo"
                    controls="controls"
                    width="400px"
                    height="260px"
                  ></video>
                  <div class="clear-btn">
                    <el-button type="danger" round @click="clearSubVideo(index)">清空视频</el-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-item">
              <span class="title">解决方案文件：</span>
              <div class="right-content">
                <div class="upload-btn uploadFiles">
                  <span>上传文件</span>
                  <input
                          type="file"
                          class="upload-file"
                          @change="handleFileChangeFile($event, index)"
                  >
                </div>
                <div v-if="item.fileUrl">
                  <div class="fileShow" @click="showFile(item.fileUrl)">{{ item.fileUrl}}</div>
                  <i class="el-icon-error delete-icon" @click="removeFile(index)"></i>
                </div>
              </div>
            </div>

            <div class="form-item">
              <span class="title">解决方案简介：</span>
              <div class="right-content">
                <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="解决方案内容"
                  v-model="item.shortContent"
                ></el-input>
              </div>
            </div>
            <div class="form-item">
              <span class="title">解决方案内容：</span>
              <div class="right-content">
                <el-input type="textarea" :rows="5" placeholder="解决方案内容" v-model="item.detail"></el-input>
              </div>
            </div>
            <div class="opt-btn">
              <i class="el-icon-circle-plus btn mr10" @click="add()"></i>
              <i
                v-if="addParams.prodSolutions.length>0"
                class="el-icon-remove btn"
                @click="remove(index)"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="form-option">
        <el-button type="primary" @click="save()">确定</el-button>
        <el-button type="info" plain @click="cancel()">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      typeList: [],
      proItems: [
        { name: "PE重包装袋", value: 1 },
        { name: "PE阀口袋", value: 2 }
      ],
      content: "", // 存储富文本框内容
      editorOption1: {}, // 所有配置
      editorOption: {
        // 定义富文本编辑器显示
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗、倾斜、下划线、删除线

            [{ header: 1 }, { header: 2 }], // 标题一、标题二
            [{ list: "ordered" }, { list: "bullet" }], // 列表

            [{ color: [] }, { background: [] }] // 字体颜色、背景颜色
          ]
        }
      },
      addParams: {
        prodName: "", // 领域
        prodImg: "",
        prodVideo: "",
        prodVideoName: '', // 产品的视频
        prodContent: "", // 正文内容
        prodDescription: "",
        remark: "", // 正文标注
        prodFunction: "",
        prodType: "", // 产品类型 1：PE重包装袋  2：阀口袋
        prodSolutions: []
      },
      fangAnItem: {
        title: "",
        imgUrl: "",
        videoUrl: "",
        videoName: '',
        detail: "",
        shortContent: "",
        fileUrl: '',
        fileName: '',

      }
    };
  },
  mounted() {
    this.typeList = this.$url.applicationRangeList();
    this.editId = this.$route.query.id;
    if (this.editId) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      let url = this.$url.getBackendApiUrl(`/api/prod/get`);
      url = this.$url.addQueryString(url, "prodId", this.editId);
      this.$http.get(url).then(res => {
        if (res.data.code == 200) {
          this.addParams = res.data.data;
          this.addParams.prodFunction = this.addParams.prodFunction * 1;
        }
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    handleFileChange(e) {
      const _this = this;
      const file = e.target.files[0];
      let url = this.$url.getBackendApiUrl(`/api/file/upload`);
      const form = new FormData();
      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)"
      });
      this.$http.post(url, form).then(
        res => {
          e.target.value = "";
          loading.close();
          if (res.data.code == 200) {
            _this.addParams.prodImg = res.data.data;
          } else {
            this.$message.error("上传失败，请稍后重试");
          }
        },
        () => {
          e.target.value = "";
          loading.close();
        }
      );
    },
    handleFileChangeVideo(e) {
      const _this = this;
      const file = e.target.files[0];
      let url = this.$url.getBackendApiUrl(`/api/file/upload`);
      const form = new FormData();
      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)"
      });
      this.$http.post(url, form).then(
        res => {
          e.target.value = "";
          loading.close();
          console.log(res);

          if (res.data.code == 200) {
            _this.addParams.prodVideo = res.data.data;
            _this.addParams.prodVideoName = file.name;

          } else {
            this.$message.error("上传失败，请稍后重试");
          }
        },
        () => {
          e.target.value = "";
          loading.close();
        }
      );
    },

    clearVideo() {
      this.addParams.prodVideo = "";
    },

    clearSubVideo(index) {
      this.addParams.prodSolutions[index].videoUrl = "";
    },

    save() {
      console.log(this.addParams);
      const _this = this;
      let url = "";
      if (!this.editId) {
        url = this.$url.getBackendApiUrl(`/api/prod/add`);
      } else {
        url = this.$url.getBackendApiUrl(`/api/prod/update`);
      }
      if (!this.addParams.prodType) {
        this.$message.warning("产品类型不能为空");
        return false;
      }
      if (!this.addParams.prodFunction) {
        this.$message.warning("领域不能为空");
        return false;
      }
      if (!this.addParams.prodName) {
        this.$message.warning("产品名称不能为空");
        return false;
      }

      if (!this.addParams.prodImg) {
        this.$message.warning("图片不能为空");
        return false;
      }
      if (!this.addParams.prodContent) {
        this.$message.warning("正文不能为空");
        return false;
      }
      this.addParams.operator = sessionStorage.getItem("username");
      this.$http.post(url, this.addParams).then(res => {
        if (res.data.code == 200) {
          this.$message.success("操作成功");
          _this.cancel();
        } else if (res.data.code == 401) {
          _this
            .$confirm(`${res.data.message}`, "提示", {
              showClose: false,
              showCancelButton: false,
              confirmButtonText: "重新登录",
              type: "warning"
            })
            .then(() => {
              _this.$router.push({ path: "/login" });
            })
            .catch(() => {});
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    add() {
      this.addParams.prodSolutions.push(
        JSON.parse(JSON.stringify(this.fangAnItem))
      );
    },
    remove(index) {
      this.addParams.prodSolutions.splice(index, 1);
    },
      removeFile(index) {
          this.addParams.prodSolutions[index].fileUrl = '';
          this.addParams.prodSolutions[index].fileName = '';

          // this.addParams.prodSolutions[index].fileUrl = '';

      },
    handleFileChangeList(e, index) {
      const _this = this;
      const file = e.target.files[0];
      let url = this.$url.getBackendApiUrl(`/api/file/upload`);
      const form = new FormData();
      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)"
      });
      this.$http.post(url, form).then(
        res => {
          e.target.value = "";
          loading.close();
          if (res.data.code == 200) {
              if (_this.addParams.prodSolutions[index].imgUrl) {
                  _this.addParams.prodSolutions[index].imgUrl = `${this.addParams.prodSolutions[index].imgUrl},${res.data.data}` ;
              } else {
                  _this.addParams.prodSolutions[index].imgUrl = `${res.data.data}` ;

              }
          } else {
            this.$message.error("上传失败，请稍后重试");
          }
        },
        () => {
          e.target.value = "";
          loading.close();
        }
      );
    },
      showJieJueImg(imgUrl) {
          const imgArry = imgUrl.split(',');
          return imgArry;
      },
      handleFileChangeFile(e, index) {
          const _this = this;
          const file = e.target.files[0];
          let url = this.$url.getBackendApiUrl(`/api/file/upload`);
          const form = new FormData();
          form.append("file", file);
          const loading = this.$loading({
              lock: true,
              text: "上传中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.6)"
          });
          this.$http.post(url, form).then(
              res => {
                  e.target.value = "";
                  loading.close();
                  if (res.data.code == 200) {
                      this.$set(_this.addParams.prodSolutions[index], 'fileUrl', res.data.data);
                      console.log(_this.addParams.prodSolutions);
                      _this.addParams.prodSolutions[index].fileName = file.name;

                  } else {
                      this.$message.error("上传失败，请稍后重试");
                  }
              },
              () => {
                  e.target.value = "";
                  loading.close();
              }
          );
      },
    handleFileChangeVideoList(e, index) {
      const _this = this;
      const file = e.target.files[0];
      let url = this.$url.getBackendApiUrl(`/api/file/upload`);
      const form = new FormData();
      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)"
      });
      this.$http.post(url, form).then(
        res => {
          e.target.value = "";
          loading.close();
          if (res.data.code == 200) {
            _this.addParams.prodSolutions[index].videoUrl = res.data.data;
            _this.addParams.prodSolutions[index].videoName = file.name;
          } else {
            this.$message.error("上传失败，请稍后重试");
          }
        },
        () => {
          e.target.value = "";
          loading.close();
        }
      );
    },
      showFile(url) {
        window.open(url);
      },
      deleteImg(index, twoIndex) {
          const imgArry = this.showJieJueImg(this.addParams.prodSolutions[index]['imgUrl']);
          imgArry.splice(twoIndex, 1);
          this.addParams.prodSolutions[index]['imgUrl'] = imgArry.join(',');
    }
  }
};
</script>

<style scoped>
.right-content .form-item .title {
  width: 110px;
}
.fanan-item {
  background: #545c6414;
  padding: 10px;
  position: relative;
  margin-bottom: 10px;
}
.fanan-item .opt-btn {
  position: absolute;
  right: -100px;
  width: 90px;
  top: 10px;
  font-size: 25px;
}
.fanan-item .opt-btn .btn {
  cursor: pointer;
  color: #545c64;
}
.mr10 {
  margin-right: 10px;
}
.mb5 {
  margin-bottom: 5px;
}

.videos {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 30px;
}

.clear-btn {
  margin-left: 30px;
}
  .uploadFiles{
    height: 30px;
    width: 82px;
    line-height: 30px;
    cursor: pointer;
    background: #409EFF;
    color: #fff;
    border-style: solid;
    font-size: 12px;
  }
  .fileShow{
    overflow: hidden;
    position: relative;
    width: 80%;
    display: inline-block;
    color: blue;
    cursor: pointer;
  }
  .delete-icon{
    font-size: 20px;
  }
  .moreImgContent{
    display: flex;
    flex-wrap: wrap;
  }
  .moreImgContent .moreImgItem{
    width: 190px;
    height: 200px;
    margin-right: 30px;
    margin-bottom: 20px;
    margin-top: 10px;
    position: relative;
    background-color: #fff;
    border: 1px solid #c0ccda;
  }
  .moreImgContent .moreImgItem img{
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .moreImgContent .moreImgItem .remove-icon{
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
  }
</style>