<template>
    <div class="page-content">
        <div class="form-content">
            <div class="form-item">
                <span class="title">类型：</span>
                <el-select class="my-input w300" v-model="addParams.logoType" placeholder="请选择">
                    <el-option
                            v-for="item in zxTypeList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="form-item">
                <span class="title">合作公司名称：</span>
                <el-input class="w300" v-model="addParams.companyName" placeholder="公司名称"></el-input>
            </div>
            <div class="form-item">
                <span class="title">合作方官网：</span>
                <el-input class="w300" v-model="addParams.officialUrl" placeholder="公司名称"></el-input>
            </div>
            <div class="form-item">
                <span class="title">合作公司logo：</span>
                <div class="right-content">
                    <div class="upload-btn">
                        <i v-if="!addParams.imgUrl" class="el-icon-plus"></i>
                        <img v-if="addParams.imgUrl" :src="addParams.imgUrl" alt="">
                        <input type="file" class="upload-file" @change="handleFileChange">
                    </div>
                </div>
            </div>
            <div class="form-option">
                <el-button type="primary" @click="save()">确定</el-button>
                <el-button type="info" plain @click="cancel()">取消</el-button>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data(){
            return {
                typeList: [
                    {name: '化工', value: 1},
                    {name: '塑料', value: 2},
                    {name: '建材', value: 3},
                    {name: '农肥', value: 4},
                    {name: '饲料', value: 5},
                    {name: '食品', value: 6},
                    {name: '其他用途', value: 7},
                ],
                zxTypeList: [
                    {name: '客户', value: 1},
                    {name: '合作方', value: 2}
                ],
                value: '',
                editId: 0,
                addParams: {
                    logoType:'',
                    companyName:'',
                    officialUrl: '', // 官网地址
                    imgUrl: ''
                },
            }
        },
        mounted() {
            this.editId = this.$route.query.id;
            if (this.editId) {
                this.getDetail();
            }
        },
        methods:{
            getDetail() {
                let url = this.$url.getBackendApiUrl(`/api/logo/get`);
                url = this.$url.addQueryString(url, 'logoId', this.editId);
                this.$http.get(url).then(res => {
                    if (res.data.code == 200) {
                        this.addParams = res.data.data;
                    }
                })
            },
            cancel() {
                this.$router.go(-1);
            },
            handleFileChange (e){
                const _this = this;
                const file = e.target.files[0];
                let url = this.$url.getBackendApiUrl(`/api/file/upload`);
                const form = new FormData();
                form.append('file', file);
                const loading = this.$loading({
                    lock: true,
                    text: '上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.6)'
                });
                this.$http.post(url, form).then(res => {
                    e.target.value= '';
                    loading.close();
                    if (res.data.code == 200) {
                        _this.addParams.imgUrl = res.data.data;

                    } else {
                        this.$message.error('上传失败，请稍后重试');
                    }
                }, () => {
                    e.target.value= '';
                    loading.close();
                })
            },
            save() {
                const _this = this;
                let url = '';
                if (!this.editId) {
                    url = this.$url.getBackendApiUrl(`/api/logo/add`);
                } else {
                    url = this.$url.getBackendApiUrl(`/api/logo/update`);
                }
      /*          if (!this.addParams.companyName) {
                    this.$message.warning('公司名称不能为空');
                    return false;
                }
                if (!this.addParams.officialUrl) {
                    this.$message.warning('合作方官网地址不能为空');
                    return false;
                }*/
                if (!this.addParams.logoType) {
                    this.$message.warning('所属类型不能为空');
                    return false;
                }
                if (!this.addParams.imgUrl) {
                    this.$message.warning('图片不能为空');
                    return false;
                }

                this.addParams.operator = sessionStorage.getItem('username');
                this.$http.post(url, this.addParams).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('操作成功');
                        _this.cancel();
                    } else if (res.data.code == 401) {
                        _this.$confirm(`${res.data.message}`, '提示', {
                            showClose: false,
                            showCancelButton: false,
                            confirmButtonText: '重新登录',
                            type: 'warning'
                        }).then(() => {
                            _this.$router.push({path: '/login'});
                        }).catch(() => {

                        });
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .form-option{
        text-align: left;
    }
    .form-content .form-item .title{
        width: 130px;
    }
</style>