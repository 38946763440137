<template>
  <div>
    <div class="search-content">
      <el-input class="w200" size="mini" v-model="paramsData.username" placeholder="用户名称"></el-input>
      <el-button class="ml8" size="mini" type="primary" @click="search">搜索</el-button>
      <router-link :to="{path: '/addUser'}">
        <el-button type="primary" class="pull-right" size="mini" plain>
          <i class="el-icon-plus"></i>添加用户
        </el-button>
      </router-link>
    </div>
    <div class="table-content">
      <el-table :data="tableData" v-loading="isLoading" border :size="'mini'" style="width: 100%" height="500">
        <el-table-column fixed type="index" prop="date" label="序号" width="50"></el-table-column>

        <el-table-column prop="username" label="用户名称"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column fixed="right" align="center" prop="date" label="操作" width="180">
          <template slot-scope="scope">
            <el-button size="mini" @click="editItem(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="open(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-content">
      <el-pagination
        :page-size="pageSize"
        :current-page="pageNum"
        layout="prev, pager, next"
        @current-change="currentChange"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      paramsData: {
        username: ""
      },
      isLoading: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.isLoading = true;
      const _this = this;
      let url = this.$url.getBackendApiUrl(`/api/user/list`);
      url = this.$url.addQueryString(url, "pageNum", this.pageNum);
      url = this.$url.addQueryString(url, "pageSize", this.pageSize);

      this.$http.post(url, this.paramsData).then(
        res => {
          this.isLoading = false;
          if (res.data.code == 200) {
            this.tableData = res.data.data.list;
            this.total = res.data.data.totalCount;
          } else if (res.data.code == 401) {
            _this
              .$confirm(`${res.data.message}`, "提示", {
                showClose: false,
                showCancelButton: false,
                confirmButtonText: "重新登录",
                type: "warning"
              })
              .then(() => {
                _this.$router.push({ path: "/login" });
              })
              .catch(() => {});
          }
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    search() {
      this.paramsData.pageNum = 1;
      this.getList();
    },
    currentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    open(item) {
      const _this = this;
      this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.deleteItem(item.userId);
        })
        .catch(() => {});
    },
    deleteItem(id) {
      let url = this.$url.getBackendApiUrl(`/api/user/delete`);
      url = this.$url.addQueryString(url, "userId", id);

      this.$http.get(url).then(res => {
        if (res.data.code == 200) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    editItem(item) {
      this.$router.push({
        path: "/addUser",
        query: { id: item.userId }
      });
    }
  }
};
</script>

<style scoped>
</style>