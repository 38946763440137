<template>
    <div class="page-content">
        <div class="form-content">
            <div class="form-item">
                <span class="title">用户名称：</span>
                <el-input class="w300" v-model="addParams.username" placeholder="用户名称"></el-input>
            </div>
            <div class="form-item">
                <span class="title">密码：</span>
                <el-input type="password" class="w300" v-model="addParams.password" placeholder="密码"></el-input>
            </div>
            <div class="form-item">
                <span class="title">确认密码：</span>
                <el-input type="password" class="w300" v-model="addParams.confirmPassword" placeholder="确认密码"></el-input>
            </div>
            <div class="form-option">
                <el-button type="primary" @click="save()">确定</el-button>
                <el-button type="info" plain @click="cancel()">取消</el-button>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data(){
            return {
                value: '',
                addParams: {
                    username:'', // 领域
                    password: '',
                    confirmPassword: '',
                },
                editId: 0,
            }
        },
        mounted() {
            this.editId = this.$route.query.id;
            if (this.editId) {
                this.addParams.userId = this.editId;
                this.getDetail();
            }
        },
        methods:{
            getDetail() {
                let url = this.$url.getBackendApiUrl(`/api/user/get`);
                url = this.$url.addQueryString(url, 'userId', this.editId);
                this.$http.get(url).then(res => {
                    if (res.data.code == 200) {
                        this.addParams.username = res.data.data.username;
                    }
                })
            },
            cancel() {
                this.$router.go(-1);
            },
            save() {
                if (!this.addParams.username) {
                    this.$message.warning('用户名不能为空');
                    return false;
                }
                if (!this.addParams.password) {
                    this.$message.warning('密码不能为空');
                    return false;
                }
                if (!this.addParams.confirmPassword) {
                    this.$message.warning('请输入确认密码');
                    return false;
                }
                if (this.addParams.password !== this.addParams.confirmPassword) {
                    this.$message.warning('确认密码和密码不一致，请重新输入');
                    return false;
                }
                const _this = this;
                let url = '';
                if (!this.editId) {
                    url = this.$url.getBackendApiUrl(`/api/user/add`);
                } else {
                    url = this.$url.getBackendApiUrl(`/api/user/update`);
                }
                this.addParams.operator = sessionStorage.getItem('username');
                this.$http.post(url, this.addParams).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('操作成功');
                        _this.cancel();
                    } else if (res.data.code == 401) {
                        _this.$confirm(`${res.data.message}`, '提示', {
                            showClose: false,
                            showCancelButton: false,
                            confirmButtonText: '重新登录',
                            type: 'warning'
                        }).then(() => {
                            _this.$router.push({path: '/login'});
                        }).catch(() => {

                        });
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .form-option{
        text-align: left;

    }
</style>