<template>
    <div class="page-content">
        <div class="form-content">
            <div class="form-item">
                <span class="title">所属领域：</span>
                <el-select class="my-input w300" v-model="addParams.applicationRange" placeholder="请选择">
                    <el-option
                            v-for="item in typeList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="form-item">
                <span class="title">资讯标题：</span>
                <el-input class="w300" v-model="addParams.title" placeholder="产品名称"></el-input>
            </div>
           <!-- <div class="form-item">
                <span class="title">资讯导言：</span>
                <el-input type="textarea" class="w300" size="mini" v-model="addParams.shortContent" placeholder="产品名称"></el-input>
            </div>-->
            <div class="form-item">
                <span class="title">资讯图片：</span>
                <div class="right-content">
                    <div class="upload-btn">
                        <i v-if="!addParams.imgUrl" class="el-icon-plus"></i>
                        <img v-if="addParams.imgUrl" :src="addParams.imgUrl" alt="">
                        <input type="file" class="upload-file" @change="handleFileChange">
                    </div>
                </div>
            </div>
            <!--<div class="form-item">
                <span class="title">资讯视频：</span>
                <div class="right-content">
                    <div class="upload-btn">
                        <i class="el-icon-plus"></i>
                    </div>
                </div>
            </div>-->
            <div class="form-item">
                <span class="title">正文内容：</span>
                <div class="right-content">
                    <quillEditor
                            style="height: 200px"
                            v-model="addParams.detail"
                            ref="content"
                            :options="editorOption"
                            @blur="onEditorBlur($event)"
                            @focus="onEditorFocus($event)"
                            @change="onContentChange($event)"
                            @ready="onEditorReady($event)">
                    </quillEditor>
                </div>
            </div>
            <div class="form-option">
                <el-button type="primary" @click="save()">确定</el-button>
                <el-button type="info" plain @click="cancel()">取消</el-button>
            </div>

        </div>
    </div>
</template>

<script>
    import { quillEditor } from 'vue-quill-editor';
    export default {
        name: "index",
        components: {quillEditor},
        data(){
            return {
                typeList: [],
                options: [],
                value: '',
                content:'', // 存储富文本框内容
                editorOption1:{},  // 所有配置
                addParams: {
                    applicationRange:'', // 领域
                    title: '',
                    imgName: '',
                    imgUrl: '',
                    shortContent: '', // 简介
                    detail: '' // 正文
                },
                editId: 0,
                editorOption: { // 定义富文本编辑器显示
                    modules:{
                        toolbar:[
                            ['bold','italic','underline','strike'], // 加粗、倾斜、下划线、删除线

                            [{'header':1},{'header':2}], // 标题一、标题二
                            [{'list':'ordered'},{'list':'bullet'}], // 列表

                            [{'color':[]},{'background':[]}], // 字体颜色、背景颜色
                        ]
                    }
                },
            }
        },
        mounted() {
            this.typeList = this.$url.applicationRangeList();
            this.editId = this.$route.query.id;
            if (this.editId) {
                this.getDetail();
            }
        },
        methods:{
            getDetail() {
                const _this = this;
                let url = this.$url.getBackendApiUrl(`/api/news/detail`);
                url = this.$url.addQueryString(url, 'newsId', this.editId);
                this.$http.get(url).then(res => {
                    if (res.data.code == 200) {
                        this.addParams = res.data.data;
                    }  else if (res.data.code == 401) {
                        _this.$confirm(`${res.data.message}`, '提示', {
                            showClose: false,
                            showCancelButton: false,
                            confirmButtonText: '重新登录',
                            type: 'warning'
                        }).then(() => {
                            _this.$router.push({path: '/login'});
                        }).catch(() => {

                        });
                    }
                })
            },
            onEditorReady(){ // 富文本准备时的事件

            },
            onEditorFocus(val){ // 富文本获得焦点时的事件
                console.log(val); // 富文本获得焦点时的内容
                // editor.enable(false); // 在获取焦点的时候禁用
            },
            onEditorBlur(val){ // 富文本失去焦点时的事件
                console.log(val); // 富文本失去焦点时的内容
            },
            onContentChange(val){ // 富文本内容改变时的事件
                console.log(val); // 富文本改变时的内容
            },
            cancel() {
                this.$router.go(-1);
            },
            handleFileChange (e){
                const _this = this;
                const file = e.target.files[0];
                let url = this.$url.getBackendApiUrl(`/api/file/upload`);
                const form = new FormData();
                form.append('file', file);
                const loading = this.$loading({
                    lock: true,
                    text: '上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.6)'
                });
                this.$http.post(url, form).then(res => {
                    e.target.value= '';
                    loading.close();
                    if (res.data.code == 200) {
                        _this.addParams.imgUrl = res.data.data;
                    } else if (res.data.code == 401) {
                        _this.$confirm(`${res.data.message}`, '提示', {
                            showClose: false,
                            showCancelButton: false,
                            confirmButtonText: '重新登录',
                            type: 'warning'
                        }).then(() => {
                            _this.$router.push({path: '/login'});
                        }).catch(() => {

                        });
                    } else {
                        this.$message.error('上传失败，请稍后重试');
                    }
                }, () => {
                    e.target.value= '';
                    loading.close();
                })
            },
            save() {
                const _this = this;
                let url = '';
                if (!this.editId) {
                    url = this.$url.getBackendApiUrl(`/api/news/add`);
                } else {
                    url = this.$url.getBackendApiUrl(`/api/news/update`);
                }
                if (!this.addParams.applicationRange) {
                    this.$message.warning('领域不能为空');
                    return false;
                }
                if (!this.addParams.title) {
                    this.$message.warning('资讯标题不能为空');
                    return false;
                }
                if (!this.addParams.imgUrl) {
                    this.$message.warning('资讯图片不能为空');
                    return false;
                }
                if (!this.addParams.detail) {
                    this.$message.warning('资讯正文不能为空');
                    return false;
                }
                this.addParams.operator = sessionStorage.getItem('username');
                this.$http.post(url, this.addParams).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('操作成功');
                        _this.cancel();
                    } else if (res.data.code == 401) {
                        _this.$confirm(`${res.data.message}`, '提示', {
                            showClose: false,
                            showCancelButton: false,
                            confirmButtonText: '重新登录',
                            type: 'warning'
                        }).then(() => {
                            _this.$router.push({path: '/login'});
                        }).catch(() => {

                        });
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            }
        }
    }
</script>

<style scoped>
</style>