<template>
  <div>
    <div class="search-content">
      <el-input class="w200" size="mini" v-model="paramsData.prodName" placeholder="产品名称"></el-input>
      <el-button class="ml8" size="mini" type="primary" @click="search">搜索</el-button>
      <router-link :to="{path: '/addProduct'}">
        <el-button type="primary" class="pull-right" size="mini" plain>
          <i class="el-icon-plus"></i>添加产品
        </el-button>
      </router-link>
    </div>
    <div class="table-content">
      <el-table :data="tableData" v-loading="isLoading" border :size="'mini'" style="width: 100%" height="500">
        <el-table-column fixed type="index" prop="date" label="序号" width="50"></el-table-column>

        <el-table-column prop="prodName" label="产品名称" width="120"></el-table-column>
        <el-table-column prop="prodImg" label="图片">
          <template slot-scope="scope">
            <img
              @click="showImg(scope.row.prodImg)"
              class="img-style"
              :src="scope.row.prodImg"
              alt="ii"
            >
          </template>
        </el-table-column>
        <el-table-column label="产品类型" align="center" width="90">
          <template
            slot-scope="scope"
          >{{scope.row.prodType==1?'PE重包装袋': 'PE阀口袋'}}</template>
        </el-table-column>
        <el-table-column label="所属领域" align="center" width="90">
          <template
            slot-scope="scope"
          >{{scope.row.prodFunction==1?'化工':scope.row.prodFunction==2?'塑料':scope.row.prodFunction==3?'建材':scope.row.prodFunction==4?'农肥':scope.row.prodFunction==5?'饲料':scope.row.prodFunction==6?'食品':'其他用途'}}</template>
        </el-table-column>
        <el-table-column prop="prodContent" show-overflow-tooltip="" label="正文内容" width="300"></el-table-column>
        <el-table-column prop="prodFile" label="解决方案数目" width="100">
          <template
                  slot-scope="scope"
          >
            <span v-if="scope.row.prodSolutions">
              {{scope.row.prodSolutions.length}}
            </span>
            <span v-else>
             0
            </span>

          </template>
        </el-table-column>
        <el-table-column prop="prodFile" label="文档路径" width="130"></el-table-column>
        <el-table-column prop="prodVideo" label="视频路径" width="130"></el-table-column>
        <el-table-column prop="createTime" label="操作时间" width="160"></el-table-column>
        <el-table-column prop="operator" label="操作人" width="100"></el-table-column>
        <el-table-column fixed="right" align="center" prop="date" label="操作" width="180">
          <template slot-scope="scope">
            <el-button size="mini" @click="editItem(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="open(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-content">
      <el-pagination
        :page-size="pageSize"
        :current-page="pageNum"
        layout="prev, pager, next"
        @current-change="currentChange"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      tableData: [],
      pageNum: 1,
      pageSize: 20,
      total: 0,
      paramsData: {
        prodName: ""
      },
      isLoading: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    showImg(imgUrl) {
      this.dialogImageUrl = imgUrl;
      this.dialogVisible = true;
    },
    getList() {
      this.isLoading = true;
      const _this = this;
      let url = this.$url.getBackendApiUrl(`/api/prod/list`);
      url = this.$url.addQueryString(url, "pageNum", this.pageNum);
      url = this.$url.addQueryString(url, "pageSize", this.pageSize);

      this.$http.post(url, this.paramsData).then(
        res => {
          this.isLoading = false;
          if (res.data.code == 200) {
            this.tableData = res.data.data.list;
            this.total = res.data.data.totalCount;
          } else if (res.data.code == 401) {
            _this
              .$confirm(`${res.data.message}`, "提示", {
                showClose: false,
                showCancelButton: false,
                confirmButtonText: "重新登录",
                type: "warning"
              })
              .then(() => {
                _this.$router.push({ path: "/login" });
              })
              .catch(() => {});
          }
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    search() {
      this.paramsData.pageNum = 1;
      this.getList();
    },
    currentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    open(item) {
      const _this = this;
      this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.deleteItem(item.prodId);
        })
        .catch(() => {});
    },
    deleteItem(id) {
      const _this = this;
      let url = this.$url.getBackendApiUrl(`/api/prod/delete`);
      url = this.$url.addQueryString(url, "prodId", id);
      this.$http.get(url).then(res => {
        if (res.data.code == 200) {
          this.$message.success("操作成功");
          this.getList();
        } else if (res.data.code == 401) {
          _this
            .$confirm(`${res.data.message}`, "提示", {
              showClose: false,
              showCancelButton: false,
              confirmButtonText: "重新登录",
              type: "warning"
            })
            .then(() => {
              _this.$router.push({ path: "/login" });
            })
            .catch(() => {});
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    editItem(item) {
      this.$router.push({
        path: "/addProduct",
        query: { id: item.prodId }
      });
    }
  }
};
</script>

<style scoped>
</style>