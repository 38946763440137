<template>
    <div>
        <div class="search-content">
            <el-input class="w200" size="mini" v-model="paramsData.phone" placeholder="手机号"></el-input>
            <el-button class="ml8" size="mini" type="primary" @click="search()">搜索</el-button>
        </div>
        <div class="table-content">
            <el-table
                    :data="tableData"
                    v-loading="isLoading"
                    border
                    :size="'mini'"
                    height="500"
                    style="width: 100%">
                <el-table-column
                        fixed
                        type="index"
                        prop="date"
                        label="序号"
                        width="50">
                </el-table-column>
                <el-table-column
                        fixed
                        prop="prodName"
                        label="包装袋类型"
                        width="130">
                    <template slot-scope="scope">
                        {{scope.row.packageType == 1 ? 'PE重包装袋(FFS袋)' : 'PE阀口袋'}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        prop="userName"
                        label="姓名"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="company"
                        width="140"
                        label="公司">
                </el-table-column>
                <el-table-column
                        prop="phone"
                        label="手机"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="email"
                        label="邮箱"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="wxNumber"
                        label="微信"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="prodName"
                        label="包装产品名称"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="prodCategory"
                        label="包装产品性质"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="applicationRange"
                        label="包装产品应用范围"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="weight"
                        label="包装产品重量"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="length"
                        label="包装袋长度"
                        width="90">
                    <template slot-scope="scope">
                        {{scope.row.length}}cm
                    </template>
                </el-table-column>
                <el-table-column
                        prop="width"
                        label="包装袋宽度"
                        width="90">
                    <template slot-scope="scope">
                        {{scope.row.width}}cm
                    </template>
                </el-table-column>
                <el-table-column
                        prop="bottom"
                        label="包装袋底部"
                        width="90">
                    <template slot-scope="scope">
                        <span v-if="scope.row.packageType == 2">{{scope.row.bottom}}cm</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="thickness"
                        label="包装袋厚度"
                        width="90">
                    <template slot-scope="scope">
                        {{scope.row.thickness}}丝
                    </template>
                </el-table-column>
                <el-table-column
                        prop="surfaceName"
                        label="包装袋膜面类型"
                        width="110">
                </el-table-column>
                <el-table-column
                        prop="surfaceConfig"
                        label="您额外的膜面配方要求"
                        width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.packageType == 1">{{scope.row.surfaceConfig}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                       prop="choubian"
                       label="是否在袋身上抽边"
                       width="120">
                   <template slot-scope="scope">
                       <span v-if="scope.row.packageType == 1">{{scope.row.choubian ? '是' : '否'}}</span>
                   </template>
               </el-table-column>
               <el-table-column
                       prop="choubianSize"
                       label="抽边尺寸"
                       width="80">
                   <template slot-scope="scope">
                       <span v-if="scope.row.packageType == 1">{{scope.row.choubianSize}}</span>
                   </template>
               </el-table-column>
               <el-table-column
                       prop="striped"
                       label="是否需要在膜面上添加防滑条纹"
                       width="120">
                   <template slot-scope="scope">
                       {{scope.row.striped ? '是' : '否'}}
                   </template>
               </el-table-column>
               <el-table-column
                       prop="breathable"
                       label="是否需要产品具有透气性"
                       width="100">
                   <template slot-scope="scope">
                       {{scope.row.breathable ? '是' : '否'}}
                   </template>
               </el-table-column>

               <el-table-column
                       prop="breathableType"
                       label="选择袋子的透气方式"
                       width="130">
                   <template slot-scope="scope">
                       <span v-if="scope.row.packageType == 1">{{scope.row.breathableType}}</span>
                   </template>
               </el-table-column>

               <el-table-column
                       prop="fengPosition"
                       label="选择Y型角封位置"
                       width="130">
                   <template slot-scope="scope">
                       <span v-if="scope.row.packageType == 1">{{scope.row.fengPosition}}</span>
                   </template>
               </el-table-column>
               <el-table-column
                       prop="pointFeng"
                       label="是否在封口增加圆点角封"
                       width="100">
                   <template slot-scope="scope">
                       <span v-if="scope.row.packageType == 1">{{scope.row.pointFeng ? '是' : '否'}}</span>
                   </template>
               </el-table-column>
               <el-table-column
                       prop="pointFengType"
                       label="角封类型"
                       width="200">
                   <template slot-scope="scope">
                       <span v-if="scope.row.packageType == 1">{{scope.row.pointFengType}}</span>
                   </template>
               </el-table-column>
               <el-table-column
                       prop="packageNumber"
                       label="需要的包装袋数量"
                       width="130">
               </el-table-column>
               <el-table-column
                       prop="unitPrice"
                       label="询价"
                       width="100">
                   <template slot-scope="scope">
                       {{scope.row.unitPrice}}元
                   </template>
               </el-table-column>
               <el-table-column
                       prop="createTime"
                       label="创建时间"
                       width="150">
               </el-table-column>
            </el-table>
        </div>
        <div class="pagination-content">
            <el-pagination
                    :page-size="pageSize"
                    :current-page="pageNum"
                    layout="prev, pager, next"
                    @current-change="currentChange"
                    :total="total">
            </el-pagination>
        </div>

    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                tableData: [],
                pageNum: 1,
                pageSize: 10,
                total: 0,
                paramsData: {
                    phone: ''
                },
                isLoading: false

            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            getList() {
                this.isLoading = true;
                const _this = this;
                let url = this.$url.getBackendApiUrl(`/api/custom/made/list`);
                url = this.$url.addQueryString(url, 'pageNum', this.pageNum);
                url = this.$url.addQueryString(url, 'pageSize', this.pageSize);

                this.$http.post(url, this.paramsData).then(res => {
                    this.isLoading = false;
                    if (res.data.code == 200) {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.totalCount;
                    } else if (res.data.code == 401) {
                        _this.$confirm(`${res.data.message}`, '提示', {
                            showClose: false,
                            showCancelButton: false,
                            confirmButtonText: '重新登录',
                            type: 'warning'
                        }).then(() => {
                            _this.$router.push({path: '/login'});
                        }).catch(() => {

                        });
                    }
                }, () => {
                    this.isLoading = false;
                });
            },
            search() {
                this.paramsData.pageNum = 1;
                this.getList();
            },
            currentChange(val) {
                this.pageNum = val;
                this.getList();
            }
        }
    }
</script>

<style scoped>
</style>