import Vue from 'vue'
import VueRouter from 'vue-router'
import customerManagement from '../views/customer-management'
import productManagement from '../views/product-management'
import addProduct from '../views/product-management/add-product'
import informationManagement from '../views/information-management'
import userManagement from '../views/user-management'
import addUser from '../views/user-management/add-user'
// import imageManagement from '../views/image-management'
import bannerManagement from '../views/image-management/banner-management'
import addBanner from '../views/image-management/add-banner'
import cooperationManagement from '../views/image-management/cooperation-management'
import addCooperation from '../views/image-management/add-cooperation'
import addInformation from '../views/information-management/add-information'
import login from '../views/login/login'
import home from '../layout/home.vue'

Vue.use(VueRouter);

const routes = [{
    path: '/',
    redirect: '/customerManagement'
    // redirect: '/login'
  },
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      title: '首页'
    },
    children: [
        {
        path: '/customerManagement',
        name: 'customerManagement',
        component: customerManagement,
        meta: {
          title: '客户管理',
          index: "1"
        }
      },
      {
        path: '/addUser',
        name: 'addUser',
        component: addUser,
        meta: {
            title: '新增用户',
            index: "1"
        }
      },
      {
        path: '/productManagement',
        name: 'productManagement',
        component: productManagement,
        meta: {
          title: '产品管理',
          index: "2"
        }
      },
      {
        path: '/addProduct',
        name: 'addProduct',
        component: addProduct,
        meta: {
          title: '新增产品',
          index: "2"

        }
      },
      {
        path: '/informationManagement',
        name: 'informationManagement',
        component: informationManagement,
        meta: {
          title: '资讯管理',
            index: "3"
        }
      },
      {
        path: '/addInformation',
        name: 'addInformation',
        component: addInformation,
        meta: {
          title: '新增资讯',
          index: "3"
        }
      },
      {
        path: '/userManagement',
        name: 'userManagement',
        component: userManagement,
        meta: {
          title: '用户管理',
            index: "4"
        }
      },
      {
        path: '/bannerManagement',
        name: 'bannerManagement',
        component: bannerManagement,
        meta: {
          title: 'banner',
          index: "5-1",
        }
      },
        {
            path: '/addBanner',
            name: 'addBanner',
            component: addBanner,
            meta: {
                title: '添加banner',
                index: "5-1",
            }
        },
      {
        path: '/cooperationManagement',
        name: 'cooperationManagement',
        component: cooperationManagement,
        meta: {
          title: '合作方logo',
          index: "5-2",
        }
      },
      {
        path: '/addCooperation',
        name: 'addCooperation',
        component: addCooperation,
        meta: {
          title: '添加合作方logo',
          index: "5-2",
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: '登录'
    }
  },
    {
      path: '*',
        redirect: '/customerManagement'
    }
];

const router = new VueRouter({
  routes
});

const whiteList = ['/login'];
router.beforeEach(async (to, from, next) => {
    // 判断是否为登录
    if (sessionStorage.getItem('token')) {
      next();
    } else {
        if (whiteList.indexOf(to.path) != -1) {
            next();
        } else {
            next('/login');
        }
    }
});
export default router