<template>
  <div>
   <!-- <div class="logon-div">
      <img src="../assets/logo.svg" alt="">
    </div>-->
    <el-menu
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      :default-active="defaultActive"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
    >
      <div v-for="(item,index) in routes" :key="index">
        <!--有二级-->
        <template v-if="item.children">
          <el-submenu :index="item.index">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{item.name}}</span>
            </template>
            <div v-for="(twoItem,indexTwo) in item.children" :key="indexTwo">
              <router-link :to="{path: twoItem.path}">
                <el-menu-item :index="item.index + '-' + twoItem.index">{{twoItem.name}}</el-menu-item>
              </router-link>
            </div>
          </el-submenu>
        </template>
        <!--无二级-->
        <template v-if="!item.children">
          <router-link :to="{path: item.path}">
            <el-menu-item :index="item.index">
              <i :class="item.icon"></i>
              <span slot="title">{{item.name}}</span>
            </el-menu-item>
          </router-link>
        </template>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "asideMy",
  data() {
    return {
      defaultActive: '1',
      isCollapse: false,
      routes: [
        {
          name: "客户管理",
          index: "1",
          icon: "el-icon-s-custom",
          path: "/customerManagement"
        },
        {
          name: "产品管理",
          index: "2",
          icon: "el-icon-tickets",
          path: "/productManagement"
        },
        {
          name: "资讯管理",
          index: "3",
          icon: "el-icon-news",
          path: "/informationManagement"
        },
        {
          name: "用户管理",
          index: "4",
          icon: "el-icon-user",
          path: "/userManagement"
        },
        {
          name: "图片管理",
          index: "5",
          icon: "el-icon-picture-outline",
          children: [
            { name: "banner列表", index: "1", path: "/bannerManagement" },
            { name: "合作方logo", index: "2", path: "/cooperationManagement" }
          ]
        }
      ]
    };
  },
    watch: {
      $route(val) {
          this.defaultActive = val.meta.index;
      }
    },
    mounted() {
        this.defaultActive = this.$route.meta.index;
    },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};
</script>

<style scoped>
  .logon-div{
    background: #fff;
    height: 50px;
  /* text-align: center; */
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  .logon-div img{
    height: 34px;
  }
</style>