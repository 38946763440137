<template>
  <div id="my-app">
    <el-container class="container-div">
      <el-aside class="aside-content" width="200px">
        <asideMy></asideMy>
      </el-aside>
      <el-main>
        <headerMy></headerMy>
        <div class="text-container">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import asideMy from "../layout/asideMy";
import headerMy from "../layout/headerMy";
export default {
  name: "home",
  components: { asideMy, headerMy }
};
</script>
<style>
#my-app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  height: 100%;
}

.el-main {
  text-align: left !important;
  padding: 0 !important;
}

body > .el-container {
  /*margin-bottom: 40px;*/
}
.container-div {
  height: 100%;
}

.aside-content {
  height: 100%;
  background: #545c64;
  overflow-x: hidden;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.text-container {
  padding: 10px !important;
  margin-top: 50px;
  /*height: calc(100vh - 50px);*/
  /*overflow: scroll;*/
}
</style>
