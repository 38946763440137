<template>
  <div class="my-header">
    <div>
      <!--<i v-if="!isCollapse" class="el-icon-s-fold"></i>-->
      <!--<i v-if="isCollapse" class="el-icon-s-unfold"></i>-->
      <span class="company-name">温州丹业包装后台管理系统</span>
    </div>
    <div>
      <span class="user-text">
        <i class="el-icon-user-solid"></i>
        {{userName}}
      </span>
      <span @click="logout()" class="logOut">
        <i class="el-icon-switch-button"></i>退出
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerMy",
  data() {
    return {
      isCollapse: false,
      userName: sessionStorage.getItem("username")
    };
  },
  methods: {
    logout() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("username");
      this.$router.push({ path: "/login" });
    }
  }
};
</script>

<style scoped>
.my-header {
  height: 50px;
  line-height: 50px;
  text-align: left;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: fixed;
  top: 0;
  left: 200px;
  right: 0;
  background: #fff;
  z-index: 99;
}
.my-header .user-text {
  background: #a9a9a9;
  text-align: center;
  padding: 3px 10px;
  border-radius: 20px;
  color: #fff;
  margin-right: 15px;
}
.company-name {
  color: #393fe3;
  font-weight: bolder;
  /*padding-left: 15px;*/
  font-size: 16px;
}

.my-header .logOut {
  color: #393fe3;
  font-weight: bolder;
  cursor: pointer;
}
</style>