import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import axios from 'axios'
// import axios from './utils/http'
import * as url from './utils/url'
// import axios from './utils/http'

import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import "./styles/main.css";
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


Vue.prototype.$http = axios;
Vue.prototype.$url = url;
Vue.config.productionTip = false;
Vue.use(ElementUI, {
    size: 'small'
});
Vue.use(VueQuillEditor);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

axios.interceptors.request.use(
  config => {
      //除登陆接口意外的接口都要加上token，才可以请求接口
      if (!config.url.endsWith('/api/login')) {
          if(sessionStorage.getItem('token')!=null){
              const token='Bearer '+sessionStorage.getItem('token');
              config.headers.common['Authorization']=token;
          }        
      } 
      return config;
  },
  error => {
      return Promise.reject(error);
  }
);