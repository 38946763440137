export function getBackendApiUrl(url) {
	return url;
}

export function addQueryString(url, param, val) {
	if (url.indexOf('?') > 0) {
		url += "&" + param + "=" + val;
	} else {
		url += "?" + param + "=" + val;
	}
	return url;
}
export function bannerType() {
    const typeList = [
        {name: '首页', value: 1},
        {name: '产品介绍', value: 2},
        {name: '企业介绍', value: 3},
        {name: '应用领域', value: 4},
        {name: '技术服务', value: 5},
        {name: '采购标准', value: 6},
        {name: '最新资讯', value: 7},
    ];
    return typeList;
}
export function applicationRangeList() {
    const typeList = [
        {name: '化工', value: 1},
        {name: '塑料', value: 2},
        {name: '建材', value: 3},
        {name: '农肥', value: 4},
        {name: '饲料', value: 5},
        {name: '食品', value: 6},
        {name: '其他用途', value: 7},
    ];
    return typeList;
}